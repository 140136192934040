/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, List } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Accommodation & Travel"}>
        <SiteHeader />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"6cciekmner9"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"marginBottom":115}}>
              
              <Title className="title-box title-box--center pt--40" content={"Logistics"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":650,"paddingTop":45}} content={"<span style=\"color: var(--black);\">Getting to the location:</span> for most, the easiest way to travel will be through <span style=\"color: var(--black);\">flight</span>. Nearby airports are <span style=\"color: var(--black);\">Malaga (closest) &amp; Granada.</span>&nbsp;&nbsp;<br><span style=\"color: var(--black);\">Traveling around:</span> the easiest way to travel around the region is via car. There is both taxi services and buses that connect Nerja and surroundings with the airports. Car rentals are available at airports.&nbsp;&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/32129/6496d18b3ecd4db486a2bed45ddb08e4_s=350x_.jpeg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/32129/6496d18b3ecd4db486a2bed45ddb08e4_s=350x_.jpeg 350w"}>
              </Image>

              <Text className="text-box text-box--center" style={{"maxWidth":650}} content={"We advice our guests to stay in/around <span style=\"color: var(--black);\">Nerja</span>&nbsp;as it is a great base for exploring the area! This is also where buses to the Wedding venue will depart from. <span style=\"color: var(--color-blend--50);\">It</span>&nbsp;is a beautiful town along this stretch of the coastline, sheltering at the foot of the Sierra de Almijara. Don´t miss its magnificent tree-lined promenade, the Balcón de Europa, jutting out into the sea and giving stunning views along the rocky shore. Nerja is also famous for its cave, the Cueva de Nerja, 4km from the town. Nerja is a great base for exploring the area! This is where buses to the Wedding venue will depart from, therefore we advice guests to stay in/around Nerja.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"arg06o8szjc"} style={{"paddingTop":1,"paddingBottom":0}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Where to stay"}>
              </Title>

              <Text className="text-box fs--18" content={"There are plenty of good options to accommodate yourself at during your stay. April is still \"off-season\", but we still recommend booking early in advance to secure a spot your are happy with.&nbsp;<br><span style=\"color: var(--black);\"><br>House/apartment rentals:</span> A plethora of options for those who want a bit more space. We recommend using the traditional websites: Airbnb/booking.com.&nbsp;<br><br><span style=\"color: var(--black);\">Hotels in Nerja:</span>&nbsp;<br>"}>
              </Text>

              <List className="text-box fs--12" content={"<li><a href=\"https://paradores.es/en/parador-de-nerja?utm_source=GoogleMyBusiness&amp;utm_medium=linkgoogle&amp;utm_campaign=paradordenerja&amp;utm_term=organico&amp;utm_content=ficha\">Hotel Parador&nbsp;&nbsp;</a></li><li><a href=\"https://www.hotelplazacavana.com/en/\">Hotel Plaza Cavana&nbsp;</a><a href=\"https://paradores.es/en/parador-de-nerja?utm_source=GoogleMyBusiness&amp;utm_medium=linkgoogle&amp;utm_campaign=paradordenerja&amp;utm_term=organico&amp;utm_content=ficha\"> &nbsp;</a></li><li><a href=\"http://www.hotelpuertadelmar.es/\">Hotel Puerta del Mar&nbsp;</a></li><li><a href=\"https://www.hotelvillaflamenca.com/en/\">Villa Flamenca, Nerja&nbsp;</a></li><li><a href=\"https://en.hotelbajamar.com/\">Hotel Bajamar&nbsp;&nbsp;</a></li><li><a href=\"https://www.hotelperlamarina.com/en/\">Hotel Perla Maria, Nerja&nbsp;</a></li><li><a href=\"https://www.mbboutiquehotel.es/en/\">MB Boutique Hotel - Adults Only (from 13years)&nbsp;</a></li><li><a href=\"https://www.onahotels.com/apart-hotel-marinas-nerja.html?source=google&amp;medium=organic&amp;campaign=google-my-business\">Hotel Marinas de Nerja&nbsp; &nbsp;</a></li><li><a href=\"https://www.hotelmenaplaza.es/en/\">Hotel Mena Plaza&nbsp;&nbsp;</a></li><li><a href=\"https://hostalplazacantarero.com/en/\">Hostal Plaza Cantarero</a></li>"} subtype={"unordered"}>
              </List>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32129/835348fe141a47a3bdeb1de5db09ea33_bri=115_s=350x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/32129/835348fe141a47a3bdeb1de5db09ea33_bri=115_s=350x_.jpeg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"visx9esqvg9"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex el--1" style={{"paddingTop":42}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"What to do in Nerja"}>
              </Title>

              <Text className="text-box fs--12" content={"There is plently to do in &amp; around Nerja. Here's a few examples of where to eat and some activities:<br><span style=\"font-weight: bold;\"><br><a href=\"https://www.labotteganerja.com/\">La  Bottega:</a></span> Italian Restaurant located by the sea<br><span style=\"font-weight: bold;\"><a href=\"https://rincondelsolnerja.com/en/\">Rinco Del Sol:</a></span> Beach Club &amp; Restaurant at Burriana Beach<br><span style=\"font-weight: bold;\"><a href=\"https://nerjadenerja.es/en/\">Restaurante Bruxelles:</a></span> Local &amp; Mediterranean food by Burriana Beach\n<br><span style=\"font-weight: bold;\"><a href=\"https://www.tripadvisor.com/Restaurant_Review-g315917-d1897626-Reviews-La_Puntilla-Nerja_Costa_del_Sol_Province_of_Malaga_Andalucia.html\">La Puntilla:</a></span> Best Tapas in town<br><span style=\"font-weight: bold;\"><a href=\"https://www.facebook.com/CopaVino1/\">Copa Vino:</a></span> For Wine lovers! Great food also. <br><span style=\"font-weight: bold;\"><a href=\"https://goo.gl/maps/n5UZDLojmyUSRF938\">Burriana Beach:</a></span>\nThe most famous beach around Nerja is Burriana Beach. Here you’ll find beach clubs, so-called “Chiringuitos” (beach restaurants) and other amenities. <br><span style=\"font-weight: bold;\"><a href=\"https://goo.gl/maps/n5UZDLojmyUSRF938\">Cantarijan:</a></span>\nA bit further down the coast you find “Cantarijan”. Getting here offers stunning views and the beach itself is surrounded by magnificent rocks. <br><span style=\"font-weight: bold;\"><a href=\"https://www.cuevadenerja.es/\">Cueva De Nerja:</a></span> Europe’s biggest caves! <br><span style=\"font-weight: bold;\"><a href=\"https://www.andaluciamia.com/en/tours/hiking-in-rio-chillar-in-frigiliana-feet-in-water-in-costa-del-sol/\">Rio Chillar Hike:</a></span> \nA spectacular hike alongside the Rio Chillar hike. Starts above Nerja and takes you through stunning mountains.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}